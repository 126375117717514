import Carousel from "react-bootstrap/Carousel";
import playStoreIcon from "../../assests/launcher_page_img/play-store-icon.png";
import ratingStarImage from "../../assests/launcher_page_img/rating-star-image.png";
import { useEffect, useState } from "react";

function CarouselBoothstrapComponent() {
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth <= 849);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isTabletView ? (
        <Carousel className="main-carousel-container">
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt=" " />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Constantijn</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          Minimis is the only minimalist launcher that has
                          transformed my phone habits
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            For the first time, I feel like I control my phone,
                            not the other way around.{" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          The added friction layers between you and those "bad"
                          dopamine-stealing apps work brilliantly. You even feel
                          guilty thinking about opening one!
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <> Minimis has changed my life. </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          My focus strengthens daily, my baseline anxiety has
                          dropped, and I get more done. My brain feels better
                          overall, like in the "good old days" of the 90s, I
                          feel calm. :)
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt=" "  />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Stephen</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          This has been one of
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            the most amazing game changers in keeping me
                            accountable to being more involved with my kiddos{" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          and not scrolling on social media or YouTube. When I
                          do need a YouTube video, I have to intentionally go to
                          my iPad or computer. It is the best on android (even
                          though I was an iPhone user) because the experience is
                          emersive.
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt="" />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Bill</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          I highly recommend this app. The UI is great and it's
                          definitely reduced the amount of time that I spend
                          distracted. Love that upon opening the app drawer it
                          opens list of 'essential apps' that you've selected,
                          thus
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            saving you from scrolling past dozens of random
                            apps.{" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          I'm looking forward to the continued development of
                          the app and future features.
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt="" />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Elisabeth</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          Minimis Launcher has helped me tamp down phone
                          distraction by making the apps I actually need easily
                          accessible, and the apps I want to minimize
                          not-so-easy to access. Perfect!
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            The home screen is simple and beautiful. I love it!{" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          I highly recommend this app for anyone who desires to
                          be thoughtful and intentional about how they use
                          technology.
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt="" />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Meghan</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          I cannot express, over and over again how much I
                          appreciate this app.
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            It has genuinely helped my scrolling, and I'm making
                            art again :)
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          thank you!
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      ) : (
        <Carousel className="main-carousel-container">
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt="" />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Constantijn</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          Minimis is the only minimalist launcher that has
                          transformed my phone habits.
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            For the first time, I feel like I control my phone,
                            not the other way around.
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          The added friction layers between you and those "bad"
                          dopamine-stealing apps work brilliantly. You even feel
                          guilty thinking about opening one!
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <> Minimis has changed my life. </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          My focus strengthens daily, my baseline anxiety has
                          dropped, and I get more done. My brain feels better
                          overall, like in the "good old days" of the 90s, I
                          feel calm. :)
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt=" " />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Stephen</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          This has been one of
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            the most amazing game changers in keeping me
                            accountable to being more involved with my kiddos{" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          and not scrolling on social media or YouTube. When I
                          do need a YouTube video, I have to intentionally go to
                          my iPad or computer. It is the best on android (even
                          though I was an iPhone user) because the experience is
                          emersive.
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt=" " />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Bill</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          I highly recommend this app. The UI is great and it's
                          definitely reduced the amount of time that I spend
                          distracted. Love that upon opening the app drawer it
                          opens list of 'essential apps' that you've selected,
                          thus
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            saving you from scrolling past dozens of random
                            apps.{" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          I'm looking forward to the continued development of
                          the app and future features.
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt="" />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Elisabeth</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          Minimis Launcher has helped me tamp down phone
                          distraction by making the apps I actually need easily
                          accessible, and the apps I want to minimize
                          not-so-easy to access. Perfect!
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            The home screen is simple and beautiful. I love it!{" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          I highly recommend this app for anyone who desires to
                          be thoughtful and intentional about how they use
                          technology.
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item style={{ width: "100vw" }}>
            <div className="Carousel-main-container">
              <div className="carousel-slder-container">
                <div className="carousel-container">
                  <div className="android-download-users-review">
                    <div className="android-download-app-rating">
                      <div className="android-play-store-icon">
                        <img src={playStoreIcon} alt=" " />
                      </div>
                      <div className="android-download-rating">
                        <div>
                          <img
                            src={ratingStarImage}
                            alt=""
                            style={{ width: "96px", height: "16px" }}
                          />
                        </div>
                        <div className="android-download-rating-text">
                          <p>-Meghan</p>
                        </div>
                      </div>
                    </div>
                    <div className="android-downlod-rating-review-center"></div>
                    <center>
                      <div className="android-download-app-review">
                        <span className="android-download-app-review-light-text">
                          I cannot express, over and over again how much I
                          appreciate this app.
                        </span>
                        <span className="android-download-app-review-bold-text">
                          <>
                            {" "}
                            It has genuinely helped my scrolling, and I'm making
                            art again :){" "}
                          </>
                        </span>
                        <span className="android-download-app-review-light-text">
                          thank you!
                        </span>
                      </div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      )}
    </>
  );
}

export default CarouselBoothstrapComponent;
